$no-context: #6f797a;
$low-risk: #17b5ab;
$medium-risk: #2e71f3;
$high-risk: #e47a3e;
$extreme-risk: #b70933;

:root {
    --risk-noContext: #{$no-context};
    --risk-low: #{$low-risk};
    --risk-medium: #{$medium-risk};
    --risk-high: #{$high-risk};
    --risk-extreme: #{$extreme-risk};
}

.risk-text-no-context {
    color: $no-context;
}

.risk-text-low {
    color: $low-risk;
}

.risk-text-medium {
    color: $medium-risk;
}

.risk-text-high {
    color: $high-risk;
}

.risk-text-extreme {
    color: $extreme-risk;
}
