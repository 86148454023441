// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use './palette' as palette;
@use './material/mat-buttons' as custom-buttons;
@use './material/mat-toggle-buttons' as custom-toggle-buttons;
@use './material/mat-form-fields' as custom-form-fields;
@use './material/mat-snackbar' as mat-snackbar;
@use './material/mat-fab' as custom-fabs;
@use './material/border' as border;
@use './material/text-color' as text-color;
@use './material/mat-tooltip' as custom-tooltip;
@use './material/mat-dialog' as custom-dialog;
@use './material/mat-icon' as custom-icon;
@use './material/mat-progress-bar' as custom-progress-bar;
@use 'sass:map';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Typography
$viso-typography: mat.define-typography-config(
    $font-family: 'Niveau Grotesk',
);

$viso-primary-palette: mat.define-palette(palette.$viso-primary);
$viso-accent-palette: mat.define-palette(palette.$viso-accent);
$viso-error-palette: mat.define-palette(palette.$viso-error);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$viso-theme: mat.define-light-theme(
    (
        color: (
            primary: $viso-primary-palette,
            accent: $viso-accent-palette,
            warn: $viso-error-palette,
        ),
        typography: $viso-typography,
    )
);

// Additional palettes
$viso-success-palette: mat.define-palette(palette.$viso-success);
$viso-warning-palette: mat.define-palette(palette.$viso-warning);
$viso-info-palette: mat.define-palette(palette.$viso-info);
$viso-secondary-palette: mat.define-palette(palette.$viso-secondary);
$viso-secondary-container-palette: mat.define-palette(palette.$viso-secondary-container);
$viso-neutral-palette: mat.define-palette(palette.$viso-neutral);
$viso-surface-container-palette: mat.define-palette(palette.$viso-surface-container);

$viso-theme: map.deep-merge(
    $viso-theme,
    (
        secondary: $viso-secondary-palette,
        success: $viso-success-palette,
        warning: $viso-warning-palette,
        info: $viso-info-palette,
        neutral: $viso-neutral-palette,
        secondary-container: $viso-secondary-container-palette,
        surface-container: $viso-surface-container-palette,

        color: (
            secondary: $viso-secondary-palette,
            success: $viso-success-palette,
            warning: $viso-warning-palette,
            info: $viso-info-palette,
            neutral: $viso-neutral-palette,
            secondary-container: $viso-secondary-container-palette,
            surface-container: $viso-surface-container-palette,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($viso-theme);
@include custom-buttons.theme($viso-theme);
@include custom-fabs.theme($viso-theme);
@include custom-toggle-buttons.theme($viso-theme);
@include custom-form-fields.theme($viso-theme);
@include mat-snackbar.theme($viso-theme);
@include border.theme($viso-theme);
@include text-color.theme($viso-theme);
@include custom-tooltip.theme($viso-theme);
@include custom-dialog.theme($viso-theme);
@include custom-icon.theme($viso-theme);
@include custom-progress-bar.theme($viso-theme);

.cdk-overlay-container {
    z-index: 1060;
}

// Override material's letter-spacing
:root * {
    letter-spacing: normal !important;
}

.mat-tooltip-newline {
    white-space: pre-line;
}
